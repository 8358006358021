
export default {
  auth: 'guest',
  data() {
    return {
      passwd: '',
      failed: false,
      blocked: false,
      loginCheckInterval: null,
      isBanned: false,
    };
  },
  created() {
    this.checkIfLoggedIn();
    this.loginCheckInterval = setInterval(() => this.checkIfLoggedIn(), 3000);
    this.failed = !!this.$route.query.failed;
    this.blocked = !!this.$route.query.blocked;
  },
  beforeDestroy() {
    if (this.loginCheckInterval) {
      clearInterval(this.loginCheckInterval);
    }
  },
  mounted() {
    this.$auth
      .loginWith('cookie', {
        data: {},
      })
      .then((res) => {
        this.$router.push(
          this.localePath({
            name: 'members',
          })
        );
      })
      .catch((e) => {});
  },

  methods: {
    checkIfLoggedIn() {
      if (!this.$auth.user) return;

      if (this.$auth.$storage.getUniversal('redirect')) {
        this.$router.push(this.$auth.$storage.getUniversal('redirect'));
      } else {
        this.$router.push(this.localePath({ name: 'members' }));
      }
    },
    async login() {
      try {
        const loginData = {
          login_username: this.formEmail,
          login_password: this.passwd,
        };
        await this.$auth.loginWith('cookie', {
          data: loginData,
        });
        this.$store.dispatch('chat/load_chats');
        this.$store.dispatch('chat/load_likes');
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        }
        if (this.hasPushSubscription) {
          this.addPushSubscriptionToAccount();
        }
      } catch (error) {
        this.failed = true;
        this.blocked = false;
        if (error.response) {
          // Get the error status, inform the user of the error.
          if (error.response.data.blocked) {
            this.blocked = true;
          }
          if (error.response.data.banned) {
            this.isBanned = true;
          }
        }

        // Unexpected error, tell the user to try again later.
      }
    },
  },
};
