import { render, staticRenderFns } from "./login.vue?vue&type=template&id=3231e613&scoped=true"
import script from "./login.vue?vue&type=script&lang=js"
export * from "./login.vue?vue&type=script&lang=js"
import style0 from "./login.vue?vue&type=style&index=0&id=3231e613&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3231e613",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewDesignImage: require('/home/code/flirt-frontends/packages/twizie/components/NewDesignImage.vue').default})
